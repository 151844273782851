import React from "react";
import "../styles/Navbar.css";

const Navbar: React.FC = () => {
  // Function to handle scroll
  const scrollToHowItWorks = () => {
    document
      .getElementById("howItWorks")
      ?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <img src="/Ollo.svg" alt="Ollo Logo" width={60} height={60} />
      </div>
      <button className="button" onClick={scrollToHowItWorks}>
        How it Works
      </button>
    </nav>
  );
};

export default Navbar;

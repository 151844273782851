import React, { useState } from "react";
import "../styles/ContactForm.css";

const ContactForm: React.FC = () => {
  const [emailFrom, setEmailFrom] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://81gud6s5r7.execute-api.us-east-1.amazonaws.com/prod/send_email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            toEmail: "info@ollo-health.com",
            fromEmail: "info@ollo-health.com",
            subject: "New Waitlisted User",
            messageBody: `You have a new enquiry from:
            email: ${emailFrom}
            name:${name} `,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send email");
      }

      // If the email is sent successfully, show a confirmation message and reset the input
      setMessage("You have been added to the waitlist.");
      setEmailFrom("");
      setName("");
    } catch (error) {
      console.error("Error:", error);
      setMessage("There was an error. Please try again.");
    }

    // Clear the message after a few seconds
    setTimeout(() => {
      setMessage("");
    }, 5000);
  };

  return (
    <section className="contact-container">
      <div className="contact-form-container">
        <h2>Let’s get in touch!</h2>
        <p>
          Have questions about how Ollo can transform your health journey? Our
          team is here to help. Reach out for personalized support
          and information.
        </p>

        <form className="contact-form" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Full Name"
            className="form-input"
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            placeholder="Email"
            className="form-input"
            onChange={(e) => setEmailFrom(e.target.value)}
          />
          <button type="submit" className="submit-button">
            Submit
          </button>
          {message && <p className="confirmation-message">{message}</p>}
        </form>
        <div className="contact-details">
          <p>
            {/* 📞 +1 212 810 0039
            <br /> */}
            📧 Info@ollo-health.com
          </p>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;

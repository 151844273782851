import React, { useState, useEffect } from "react";
import "../styles/Hero.css";
import SignupForm from "./SignupForm";

const Hero: React.FC = () => {
  const [currentText, setCurrentText] = useState(""); // Current displayed text
  const [currentWordIndex, setCurrentWordIndex] = useState(0); // Current word index
  const fullTextArray = ["Better, Longer, Healthier "]; // Array of words to animate
  const [isTyping, setIsTyping] = useState(true); // Typing state
  const [isDeleting, setIsDeleting] = useState(false); // Deleting state

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const type = () => {
      const fullText = fullTextArray[currentWordIndex]; // Get the current word dynamically

      setCurrentText((prev) => {
        const nextText = fullText.slice(0, prev.length + 1); // Add one letter

        if (nextText === fullText) {
          // Word fully typed
          setIsTyping(false);
          return nextText;
        }

        return nextText; // Update currentText
      });
    };

    const deleteText = () => {
      const fullText = fullTextArray[currentWordIndex]; // Get the current word dynamically

      setCurrentText((prev) => {
        const nextText = prev.slice(0, prev.length - 1); // Remove one letter

        if (nextText === "") {
          // Word fully deleted
          console.log("Word Fully Deleted:", fullText);
          setIsDeleting(false);
          setIsTyping(true); // Start typing the next word
          setCurrentWordIndex(
            (prevIndex) => (prevIndex + 1) % fullTextArray.length
          ); // Move to next word, loop back to [0]
          return nextText; // Return an empty string
        }

        return nextText; // Continue deleting
      });
    };

    if (isTyping) {
      timer = setTimeout(type, 200); // Typing speed
    } else if (isDeleting) {
      timer = setTimeout(deleteText, 200); // Deleting speed
    }

    return () => {
      clearTimeout(timer); // Cleanup timer
    };
  }, [isTyping, isDeleting, currentText]); // Track isTyping, isDeleting, and currentText

  return (
    <section className="hero">
      <div className="hero-content">
        <h2 className="animatedTitle">
          Live <span>{currentText}</span>
          <span className="cursor">
            {currentText === fullTextArray[currentWordIndex] ? "!" : "|"}
          </span>
          {/* Cursor effect */}
        </h2>
        <p className="subtitle">
          Your health story, reimagined. All your data in one place, empowering
          better care and healthier outcomes
        </p>
        <SignupForm />
      </div>
    </section>
  );
};

export default Hero;

import React from "react";
import "../styles/HowItWorks.css";
import { FaClipboardCheck, FaStethoscope, FaHeartbeat } from "react-icons/fa";
import { BiConversation } from "react-icons/bi";
import { FiBarChart2 } from "react-icons/fi";
import { MdOutlineTrackChanges } from "react-icons/md";

const HowItWorks: React.FC = () => {
  return (
    <section className="how-it-works" id="howItWorks">
      <div className="how-it-works-container">
        <h2>For Physicians</h2>
        <div className="how-it-works-content">
          <div className="for-physicians">
            <div className="icon-container">
              <FaClipboardCheck size={30} />
            </div>
            <h3>Streamlined Practice Management</h3>
            <p>
              Ollo automates SOAP notes, billing, coding, and referrals, freeing
              physicians from administrative burdens. This reduces burnout and
              enables more meaningful patient care.
            </p>
          </div>
          <div className="for-physicians">
            <div className="icon-container">
              <FaStethoscope size={30} />
            </div>
            <h3>Holistic and Personalized Patient-Services</h3>
            <p>
              Using multimodal data—medical history, wearables, and patient
              inputs—Ollo creates holistic, tailored care plans, helping
              physicians address root issues and guide patients toward their
              goals.
            </p>
          </div>
          <div className="for-patients">
            <div className="icon-container">
              <FiBarChart2 size={30} />
            </div>
            <h3>Continuous and Granular Health Insights</h3>
            <p>
              Ollo provides real-time insights from wearables, labs, and patient
              activities, enabling physicians to monitor progress, adjust plans
              dynamically, and stay connected with patients for consistent,
              proactive care.
            </p>
          </div>
        </div>
      </div>
      <div className="how-it-works-container">
        <h2>For Patients</h2>
        <div className="how-it-works-content">
          <div className="for-physicians">
            <div className="icon-container">
              <MdOutlineTrackChanges size={30} />
            </div>
            <h3>Empowered Health Management</h3>
            <p>
              Track health goals like weight, fitness, or nutrition through
              Ollo’s app. Personalized insights help you make informed decisions
              and stay in control of your health journey.
            </p>
          </div>
          <div className="for-physicians">
            <div className="icon-container">
              <FaHeartbeat size={30} />
            </div>
            <h3>Preventive Care for Longevity</h3>
            <p>
              Ollo offers AI-driven recommendations to help you build healthier
              habits and reduce risks, empowering you to improve your well-being
              and extend your healthspan.
            </p>
          </div>
          <div className="for-patients">
            <div className="icon-container">
              <BiConversation size={30} />
            </div>
            <h3>Stay Connected with Your Doctor</h3>
            <p>
              Easily share updates, schedule follow-ups, and seek guidance
              through Ollo. Your doctor can monitor your data in real time to
              provide personalized, timely support.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;

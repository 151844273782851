import React from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import "./App.css";
import HowItWorks from "./components/HowItWorks";
import ContactForm from "./components/ContactForm";

const App: React.FC = () => {
  return (
    <div className="App">
      <Navbar />
      <div className="right-side">
      <Hero />
      <HowItWorks />
      <ContactForm />
      </div>
    </div>
  );
};

export default App;

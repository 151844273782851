import React, { useState } from "react";
import "../styles/SignupForm.css";

const SignupForm: React.FC = () => {
  const [emailFrom, setEmailFrom] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://81gud6s5r7.execute-api.us-east-1.amazonaws.com/prod/send_email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            toEmail: "info@ollo-health.com",
            fromEmail: "info@ollo-health.com",
            subject: "New Waitlisted User",
            messageBody: `You have a new waitlisted user: ${emailFrom}`,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send email");
      }

      // If the email is sent successfully, show a confirmation message and reset the input
      setMessage("You have been added to the waitlist.");
      setEmailFrom(""); // Reset the input field
    } catch (error) {
      console.error("Error:", error);
      setMessage("There was an error. Please try again.");
    }

    // Clear the message after a few seconds
    setTimeout(() => {
      setMessage("");
    }, 5000);
  };

  return (
    <form className="signup-form" onSubmit={handleSubmit}>
      <input
        type="email"
        id="email"
        name="email"
        value={emailFrom}
        onChange={(e) => setEmailFrom(e.target.value)}
        required
        placeholder="Type your email here"
      />
      <button type="submit" className="signup-btn">
        Join waitlist
      </button>
      {message && <p className="confirmation-message">{message}</p>}
    </form>
  );
};

export default SignupForm;
